import React from 'react'
import { Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import DataWithLabel from './DataWithLabel'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import './SingleEntityDetails.css'
import { iLooseObject } from 'pages/DataHub/filters/types'
import {
  iCo2CalculationProcessModel,
  iCo2Value,
  elementEmissionContribution,
  elementShare,
  iCo2ValuesChipLabel,
  eCo2Details,
  iEnergyProduction,
} from 'store/types'
import { useSelector } from 'react-redux'
import { singleEntityState } from 'store/slices/singleEntity'
import { useCallback } from 'react'
import { urlToObject } from 'config/lib'
import { CardRow } from './CardRow'
import { RoutePaths } from 'routes/paths'

interface iProps {
  data: iCo2Value
}

const SingleEntityCo2ProcessDetails = ({ data }: iProps) => {
  const { t } = useTranslation()
  const singleEntity = useSelector(singleEntityState)?.singleEntity?.data
  // Helper function to capitalize the first letter of each energy type
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
  const Graphcolors = [
    '#4CAF50',
    '#8BC34A',
    '#CDDC39',
    '#FFC107',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#607D8B',
    '#03A9F4',
    '#00BCD4',
  ] // Eco-friendly color palette

  const formatEnergyComposition = (
    composition: Partial<
      Record<keyof typeof data.productionProcessEnergyComposition, number>
    >
  ) => {
    const colors = Graphcolors // Example colors array

    return Object.entries(composition)
      .filter(([_, value]) => value !== undefined && value !== 0) // Filter out undefined or zero values
      .map(([key, value], index) => ({
        key: capitalizeFirstLetter(key), // Capitalize the first letter of the key
        value: value as number, // Ensure value is treated as a number
        color: colors[index % colors.length], // Assign a color from the colors array
      }))
  }

  const formatElementEmissionContributions = (
    contributions: elementEmissionContribution[]
  ) => {
    const colors = Graphcolors // Example colors array

    return contributions.map((contribution, index) => ({
      name: contribution.elementName, // Use the element name
      percentage: contribution.contributionPercentage, // Use the contribution percentage directly (assumed to be in decimal form)
      color: colors[index % colors.length], // Cycle through the color array for different contributions
    }))
  }

  const formatElementShares = (shares: elementShare[]) => {
    const colors = Graphcolors // Example colors array

    return shares.map((share, index) => ({
      name: share.nameEn, // Use the English name of the share
      percentage: share.shareAmount, // Use the share amount directly (assumed to be in decimal form)
      color: colors[index % colors.length], // Cycle through the color array for different shares
    }))
  }
  const formatCategoryName = (categoryName: string) => {
    // Split the category name by capital letters and apply capitalizeFirstLetter to each word
    return categoryName
      .split(/(?=[A-Z])/)
      .map((word) => capitalizeFirstLetter(word))
      .join(' ')
  }
  const formatEnergyProductionShares = (
    energyProduction: iEnergyProduction
  ) => {
    const colorPalettes = {
      nonRenewable: ['#A93226', '#CB4335', '#E74C3C', '#EC7063', '#F1948A'],
      conventionalThermal: [
        '#AF601A',
        '#CA6F1E',
        '#DC7633',
        '#E67E22',
        '#F39C12',
      ],
      renewable: ['#28B463', '#52BE80', '#82E0AA', '#ABEBC6', '#D5F5E3'],
      nonSpecified: ['#5D6D7E', '#85929E', '#AAB7B8', '#CCD1D1', '#D5D8DC'],
    }

    const categories = [
      'nonRenewable',
      'conventionalThermal',
      'renewable',
      'nonSpecified',
    ]

    // Calculate the total share amount across all categories
    const totalShare = categories.reduce((total, category) => {
      const categoryShares =
        energyProduction[category as keyof iEnergyProduction]
      const categoryTotal = categoryShares.reduce(
        (sum, share) => sum + share.shareAmount,
        0
      )
      return total + categoryTotal
    }, 0)

    // Format each category with its shares and relative sizes
    return categories.map((category, categoryIndex) => {
      const shares = energyProduction[category as keyof iEnergyProduction]
      const categoryTotal = shares.reduce(
        (sum, share) => sum + share.shareAmount,
        0
      )

      const colors = colorPalettes[category as keyof typeof colorPalettes]

      return {
        categoryName: category,
        categoryPercentage: (categoryTotal / totalShare) * 100, // Category size relative to total
        shares: shares.map((share, index) => ({
          productionType: share.productionTypeEn,
          shareAmount: share.shareAmount,
          percentage: (share.shareAmount / totalShare) * 100, // Proportional size relative to the overall total
          color: colors[index % colors.length], // Use category-specific colors
        })),
      }
    })
  }

  const location = useLocation()
  const [, setSearchParams] = useSearchParams()
  const urlPath = location.search
  const singleEntityParams: iLooseObject = urlToObject(urlPath)
  const contextName: string = singleEntityParams['contextName'][0]
  const typeName = contextName.substring(contextName.lastIndexOf('.') + 1)
  const co2Source = data?.co2Source
  const isMetalType = typeName === 'metals'
  const navigate = useNavigate()
  const cardSourceRow = useCallback(() => {
    if (co2Source || isMetalType) {
      return (
        <CardRow
          label={t('singleEntity.card.externalSources')}
          value={t('general.seeMore')}
          onClick={() => {
            singleEntityParams['id'] = data['id'] || null
            singleEntityParams['type'] = eCo2Details.sources
            delete singleEntityParams['search']
            setSearchParams(singleEntityParams)
          }}
        />
      )
    }
  }, [data, co2Source, isMetalType, setSearchParams, singleEntityParams, t])
  const navigateToKnowledgeHub = useCallback(
    (knowledgeHubLocation: string) => {
      switch (knowledgeHubLocation) {
        case 'energy':
          navigate(RoutePaths.knowledgeHubEnergy)
          break
        case 'materials':
          navigate(RoutePaths.knowledgeHubMaterials)
          break
        case 'logistics':
          navigate(RoutePaths.knowledgeHubLogistics)
          break
        case 'production':
          navigate(RoutePaths.knowledgeHubProduction)
          break
        case 'electronics':
          navigate(RoutePaths.knowledgeHubElectronics)
          break
        default:
          break
      }
    },
    [navigate]
  )

  return (
    <Paper className="min-h-[200px] p-4 mx-4 mb-4">
      <div className="mb-3 p-2">
        {data?.calculationProcessModel?.productionProcessDescription && (
          <div className="mb-6">
            {' '}
            {/* Increased margin-bottom for larger spacing */}
            <CardRow
              label={t('singleEntity.details.description')}
              value={
                data?.calculationProcessModel?.productionProcessDescription
              }
            />
          </div>
        )}
        {data?.calculationProcessModel?.scopeSpecification && (
          <div className="">
            {' '}
            {/* Increased margin-bottom for larger spacing */}
            <CardRow
              label={t('singleEntity.details.scopeSpecification')}
              value={data?.calculationProcessModel?.scopeSpecification}
            />
          </div>
        )}
        {data?.calculationProcessModel?.remark && (
          <div className="">
            {' '}
            {/* Increased margin-bottom for larger spacing */}
            <CardRow
              label={t('singleEntity.details.remark')}
              value={data?.calculationProcessModel?.remark}
            />
          </div>
        )}
        {data?.calculationProcessModel?.energyProductionShares && (
          <>
            <CardRow
              label={t('singleEntity.details.energyProductionShares')}
              value="" // Placeholder, as we will render the bar separately
            />

            {/* Container for category labels */}
            <div className="relative w-full flex justify-between">
              {formatEnergyProductionShares(
                data?.calculationProcessModel?.energyProductionShares
              ).map(
                (
                  { categoryName, categoryPercentage, shares },
                  categoryIndex,
                  arr
                ) =>
                  shares.length > 0 && ( // Only show label if there are shares in the category
                    <div
                      key={categoryIndex}
                      className="text-center text-sm font-semibold"
                      style={{
                        width: `${categoryPercentage}%`, // Width based on total share amount relative to overall total
                        float: 'left', // Align main categories side by side
                      }}
                    >
                      {formatCategoryName(categoryName)}{' '}
                      {/* Capitalize each word in the category name */}
                    </div>
                  )
              )}
            </div>

            {/* Main graph container */}
            <div className="relative w-full bg-gray-200 rounded-full h-10 overflow-hidden flex mb-4">
              {formatEnergyProductionShares(
                data?.calculationProcessModel?.energyProductionShares
              ).map(
                (
                  { categoryName, categoryPercentage, shares },
                  categoryIndex,
                  arr
                ) =>
                  shares.length > 0 && ( // Only show section if there are shares in the category
                    <div
                      key={categoryIndex}
                      className="h-full flex"
                      style={{
                        width: `${categoryPercentage}%`, // Width based on total share amount relative to overall total
                        float: 'left', // Align main categories side by side
                        borderRight:
                          categoryIndex < arr.length - 1 &&
                          arr
                            .slice(categoryIndex + 1)
                            .some((cat) => cat.shares.length > 0)
                            ? '2px solid #000'
                            : 'none', // Only apply border if not the last category with data
                        display: 'flex', // Use flex to align subsections
                      }}
                    >
                      {shares.map(
                        ({ productionType, percentage, color }, index) => (
                          <div
                            key={index}
                            className="h-full flex items-center justify-center"
                            style={{
                              flexGrow: percentage, // Use flex-grow to allow proper expansion within the flex container
                              backgroundColor: color, // Color for each sub-segment
                              color: '#fff', // Text color for contrast
                              fontSize: '0.75rem', // Small font size for readability
                              fontWeight: 'bold', // Bold text for readability
                              textAlign: 'center', // Center the text horizontally
                            }}
                            title={`${productionType}: ${percentage.toFixed(
                              2
                            )}%`} // Tooltip with name and percentage
                          >
                            {percentage >= 5
                              ? `${productionType}: ${percentage.toFixed(2)}%`
                              : ''}{' '}
                            {/* Show text only if large enough */}
                          </div>
                        )
                      )}
                    </div>
                  )
              )}
            </div>
          </>
        )}

        {data?.calculationProcessModel?.productionProcessEnergyComposition && (
          <div className="mb-6">
            {' '}
            {/* Increased margin-bottom for larger spacing */}
            <CardRow
              label={t(
                'singleEntity.details.productionProcessEnergyComposition'
              )}
              value="" // Placeholder to indicate usage
            />
            <div className="relative w-full bg-gray-200 rounded-full h-8 overflow-hidden">
              {formatEnergyComposition(
                data.calculationProcessModel.productionProcessEnergyComposition
              ).map(({ key, value, color }, index) => (
                <div
                  key={index}
                  className="h-full flex items-center justify-center"
                  style={{
                    width: `${value * 100}%`, // Convert to percentage
                    backgroundColor: color, // Use the assigned color
                    float: 'left', // Align segments side by side
                    color: '#fff', // Text color for contrast
                    fontSize: '0.75rem', // Small font size to fit within the bars
                    fontWeight: 'bold', // Bold text for readability
                    textAlign: 'center', // Center the text horizontally
                  }}
                  title={`${key}: ${(value * 100).toFixed(2)}%`} // Tooltip with label and percentage
                >
                  {value * 100 >= 5
                    ? `${key}: ${(value * 100).toFixed(2)}%`
                    : ''}{' '}
                  {/* Show text only if the segment is large enough */}
                </div>
              ))}
            </div>
          </div>
        )}
        {singleEntity?.features?.elementShares && (
          <div className="mb-6">
            {' '}
            {/* Increased margin-bottom for larger spacing */}
            <CardRow
              label={t('singleEntity.details.elementShares')}
              value="" // Placeholder, as we will render the bar separately
            />
            <div className="relative w-full bg-gray-200 rounded-full h-8 overflow-hidden">
              {formatElementShares(singleEntity.features.elementShares).map(
                ({ name, percentage, color }, index) => (
                  <div
                    key={index}
                    className="h-full flex items-center justify-center"
                    style={{
                      width: `${percentage * 100}%`, // Convert to percentage
                      backgroundColor: color, // Use the assigned color
                      float: 'left', // Align segments side by side
                      color: '#fff', // Text color for contrast
                      fontSize: '0.75rem', // Small font size to fit within the bars
                      fontWeight: 'bold', // Bold text for readability
                      textAlign: 'center', // Center the text horizontally
                    }}
                    title={`${name}: ${(percentage * 100).toFixed(2)}%`} // Tooltip with name and percentage
                  >
                    {percentage * 100 >= 5
                      ? `${name}: ${(percentage * 100).toFixed(2)}%`
                      : ''}{' '}
                    {/* Show text only if the segment is large enough */}
                  </div>
                )
              )}
            </div>
          </div>
        )}
        {data?.calculationProcessModel?.elementEmissionContributions && (
          <div className="mb-6">
            {' '}
            {/* Increased margin-bottom for larger spacing */}
            <CardRow
              label={t('singleEntity.details.elementEmissionContributions')}
              value="" // Placeholder, as we will render the bar separately
            />
            <div className="relative w-full bg-gray-200 rounded-full h-8 overflow-hidden">
              {formatElementEmissionContributions(
                data.calculationProcessModel.elementEmissionContributions
              ).map(({ name, percentage, color }, index) => (
                <div
                  key={index}
                  className="h-full flex items-center justify-center"
                  style={{
                    width: `${percentage * 100}%`, // Convert to percentage
                    backgroundColor: color, // Use the assigned color
                    float: 'left', // Align segments side by side
                    color: '#fff', // Text color for contrast
                    fontSize: '0.75rem', // Small font size to fit within the bars
                    fontWeight: 'bold', // Bold text for readability
                    textAlign: 'center', // Center the text horizontally
                  }}
                  title={`${name}: ${(percentage * 100).toFixed(2)}%`} // Tooltip with name and percentage
                >
                  {percentage * 100 >= 5
                    ? `${name}: ${(percentage * 100).toFixed(2)}%`
                    : ''}{' '}
                  {/* Show text only if the segment is large enough */}
                </div>
              ))}
            </div>
          </div>
        )}
        <CardRow
          label={t('navbarSubHeader.knowledgeHub')}
          value={'See more'}
          onClick={() =>
            window.open(`/knowledge-hub/${contextName.split('.')[1]}`, '_blank')
          }
        />
        <CardRow
          label={t('singleEntity.card.mainSource')}
          value={'sustamize GmbH'}
        />
        {/* only show source if exists*/}
        {co2Source ? cardSourceRow() : isMetalType ? cardSourceRow() : null}
      </div>
    </Paper>
  )
}

export default SingleEntityCo2ProcessDetails
